.prev-result-box {
    .bx-like {
        color: $tealish-green !important;
    }

    .bx-dislike {
        color: $danger !important;
    }

    .bx-trophy {
        color: $yellowish-orange !important;
    }
}