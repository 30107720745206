.scan-main {
    min-height: 100vh;
    width: 100%;
    background: url("../../assets/images/scan-bg.svg") no-repeat 0 0 / 100% auto;
    padding: size(35);
    .scan-logo {
        max-width: size(200);
    }
    @include media(down, $breakpoint-xl){
        padding: size(25);
    }
}
.scan-inner {
    background-color: $white;
    padding: size(55) size(87) 0 size(87);
    max-width: 1140px;
    margin: size(50) auto 0;
    box-shadow: $box-shadow;
    @include media(down, $breakpoint-xl){
        padding: size(40) size(40) 0 size(40);
        margin-top: size(30);
        max-width: 88%;
    }
    h4 {
        font-size: size(24);
        font-weight: $font-regular;
        margin-bottom: size(24);
    }
    .need-help {
        padding-top: size(26);
    }
    .help-btn {
        font-size: size(13);
        font-weight: $font-bold;
    }
}
.scan-top {
    @include flex-only;
    .scan-left {
        width: calc(100% - 220px);
    }
    .scan-right {
        width: size(200);
        text-align: center;
        .custom-checkbox {
            margin-top: size(5);
            &.sm {
                label {
                    margin-right: 0;
                    font-size: size(13);
                }
                input[type=checkbox] {
                    + span {
                        top: -1px;
                    }
                }
            }
        }
    }
}
.scan-ol {
    margin: 0 0 size(20) 0;
    padding: 0 0 0 size(20);
    li {
        list-style-type: decimal;
        margin-bottom: size(22);
    }
    .bx-menu-alt-left {
        font-size: size(23);
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;
        margin-left: 3px;
        margin-right: 1px;
    }
    .icon-website {
        font-size: size(28);
        color: $primary;
        display: inline-block;
        vertical-align: middle;
        margin-left: 6px;
        margin-right: 6px;
    }
}
.scan-image {
    text-align: center;
}