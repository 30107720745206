.my-account-section {
  .profile-box {
    width: 120px;
    display: block;
    text-align: center;

    h5 {
      margin-top: size(5);
      margin-bottom: 0;
    }
  }

  .profile-img {
    position: relative;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: $border-radius;
    }

    .camera-ic {
      font-size: size(16);
      color: $white;
      position: absolute;
      bottom: 6px;
      right: 10px;
    }
  }

  .my-account-info {
    margin-top: size(30);
  }

  .account-menu {
    ul {
      li {
        a {
          font-weight: $font-bold;
          font-size: size(16);
          line-height: size(22);
          letter-spacing: size(-0.25);
          color: $dark;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid $border-color-light;
          padding: size(22);

          em {
            font-size: size(24);
            color: $gray;
          }

          &:hover {
            color: $primary;

            em {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

/*== Change Password Start ==*/
.change-password {
  h2 {
    margin-bottom: size(30);
  }

  p {
    text-align: center;
    margin-bottom: 0;
    color: $gray;

    a {
      font-weight: $font-bold;
      color: $dark;

      &:hover {
        color: $primary;
      }
    }
  }
}

.change-password-modal {
  ul {
    margin-bottom: size(30);

    li {
      font-size: size(15);
      line-height: size(22);
      color: $gray;
      letter-spacing: size(-0.12);
      font-weight: $font-regular;
      display: flex;
      align-items: flex-start;
      margin-bottom: size(6);

      .bx {
        font-size: size(12);
        color: $gray;
        margin-right: size(6);
        margin-top: size(6);
      }

      &.valid {
        color: $success;
      }

      &.invalid {
        color: $danger;
      }
    }
  }

  .modal-body {
    max-width: 330px;
    margin: 0 auto;
  }

  .form-group {
    .form-control {
      padding: size(12) size(80) size(12) size(24);
    }

    .field-icon {
      color: $white;
      background: $primary;
      top: 0;
      right: 0;
      bottom: 0;
      width: 56px;
      border-radius: 0 24px 24px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #ec2f3a;
      }
    }
  }
}

/*== Change Password End ==*/

/*== Notification Settings Start ==*/
.notification-settings {
  .setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color-light;
    padding: size(22);

    h6 {
      margin-bottom: 0;
    }
  }
}

/*== Notification Settings End ==*/

/*== Notification Start ==*/
.notification-section {
  margin-bottom: size(106);

  .notification-row {
    margin-bottom: size(26);
  }

  .notification-header {
    margin-bottom: size(12);
    @include flex-between;

    h6 {
      font-size: size(15);
      font-weight: $font-regular;
      line-height: size(22);
      color: $dark;
      letter-spacing: size(-0.12);
      margin-bottom: 0;
    }

    a {
      font-size: size(13);
      font-weight: $font-regular;
      line-height: size(18);
      letter-spacing: size(-0.03);
    }
  }

  .notification-item {
    background: rgba($secondary, 5%);
    border: 1px solid transparent;
    padding: size(11) size(14);
    margin-bottom: size(10);
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    border-radius: $border-radius;

    .icon-box {
      background: rgba($secondary, 20%);
      border: 1px solid transparent;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: size(5);
      border-radius: $border-radius;
      flex: 0 0 auto;

      .bx {
        font-size: size(24);
        color: $secondary;
      }
    }

    // .notification-delete{
    //     position: absolute;
    //     right: 50px;
    // }
    .notification-info {
      width: calc(100% - 48px);
      padding-left: size(14);

      h6 {
        font-size: size(15);
        font-weight: $font-regular;
        line-height: size(16);
        color: $dark;
        letter-spacing: size(-0.12);
        min-height: 20px;
        margin-bottom: 3px;

        &:hover {
          color: $primary;
        }
      }

      p {
        font-size: size(11);
        font-weight: $font-bold;
        line-height: size(16);
        color: $secondary;
        letter-spacing: size(0.1);
        margin-bottom: 0;
        text-transform: uppercase;
      }

      .notification-delete {
        .bx-trash {
          font-size: size(24);

          @include media(down, $breakpoint-xl) {
            font-size: size(22);
          }
        }
      }
    }

    &.read-notification {
      background: $white;
      border: 1px solid $border-color;

      .icon-box {
        background: $white;
        border: 1px solid $border-color;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .bx {
          color: $primary;
        }
      }

      // .notification-info{
      //     p{
      //         color: $gray;
      //     }
      // }
    }
  }
}

.notification-page {
  .profile-section {
    border-top: 1px solid $border-color-light;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    background: $white;
    padding-left: $nav-sidebar-width;

    @include media(down, $breakpoint-xxl) {
      padding-left: 300px;
    }

    @include media(down, $breakpoint-xl) {
      padding-left: 240px;
    }

    .profile-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: size(20);

      .arrow-ic {
        font-size: size(24);
        color: $dark;
      }

      .profile-info {
        display: flex;
        align-items: center;

        .profile-img {
          flex: 0 0 auto;
          margin-right: size(12);

          img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            border-radius: $border-radius;
          }
        }
      }
    }
  }
}

/*== Notification End ==*/