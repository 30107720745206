@import "variable";
@import "mixin";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
}
/*Headings*/
h1,
.h1 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  font-size: size(32);
  line-height: size(44);
  letter-spacing: size(-0.7);
  color: $dark;
  @include media(down, $breakpoint-sm){
    font-size: size(24);
  }
}
h2,
.h2 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  font-size: size(24);
  line-height: size(32);
  letter-spacing: size(-0.45);
  color: $dark;
}
h3,
.h3 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  font-size: size(22);
  line-height: size(30);
  letter-spacing: size(-0.4);
  color: $dark;
}
h4,
.h4 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  font-size: size(20);
  line-height: size(28);
  letter-spacing: size(-0.34);
  color: $dark;
}
h5,
.h5 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  font-size: size(18);
  line-height: size(24);
  letter-spacing: size(-0.25);
  color: $dark;
}
h6,
.h6 {
  font-family: $font-family-base;
  font-weight: $font-bold;
  font-size: size(16);
  line-height: size(22);
  letter-spacing: size(-0.25);
  color: $dark;
}

p {
  font-family: $font-family-base;
  font-weight: $font-regular;
  font-size: size(16);
  line-height: size(22);
  letter-spacing: size(-0.25);
  color: $dark;
}

font {
  font-family: $font-family-base;
}

a {
  color: $primary;
  text-decoration: none;
  @include Transition(all 0.3s ease-in-out);
  &:hover {
    text-decoration: none;
    color: darken($primary, 10);
  }
}

ul,
li {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
