/* =========== IMAGE PATH VARIABLE ============= */
$assetPath: "/assets/images/";
/* Usage of bacground image */
//background: url(#{$assetPath}/background.jpg) repeat-x fixed 0 0;

// Color system
$white: #ffffff;
$black: #000000;
$primary: #2e3092;
$secondary: #ed1c24;
// $secondary: #13a8b6;
$success: #47b872;
$info: #1c3aa7;
$warning: #f3c32f;
$danger: #ed3e3e;
$light: #aedaea;
$dark: #1a1a1a;
$gray: #999999;
$tertiary: #facbcc;
$light-gray: #fafafb;
$gray-200: #999898;
$gray-btn: #eeeeee;
$purple: #6758f3;
$pink: #fa578f;
$chocolate: #d86116;
$green: #42a48e;
$red: #ff0000;
$red-dark: #ed1c24;
$light-yellow: #ff9900;
$sky-blue: #d0e0f7;
$green-light: #00b050;
$coffee: #984806;
$light-pink: #cc00cc;
$light-blue: #393185;
$light-blue: #393185;
$tealish-green: #47B873;
$yellowish-orange: #f69904;
$box-shadow: #606170;
$border-color: rgba($gray, 40%);
$border-color-light: rgba($gray, 20%);
$read-only: #ececec;
$pastel-grey: #cccccc; 

// Body
// Settings for the `<body>` element.
$body-bg: $light-gray;
$body-color: $black;

// Links
// Style anchor elements.
$link-color: theme-color("primary");
$link-decoration: none;
$link-hover-color: darken($primary, 10);

// Font
$font-boxicon: "boxicons" !important;
$font-family-base: "Lato", sans-serif;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-custom: 16;
$font-light: 300;
$font-regular: 400;
$font-semibold: 600;
$font-bold: 700;
$font-black: 900;
$line-height-base: 1.25;

$font-size-lg: $font-size-base * 1.25; //20px
$font-size-sm: $font-size-base * 0.875; //14px

// Style p element.
$paragraph-margin-bottom: 1rem;

// Media BreakPoint
$breakpoint-xxxl: 1600px;
$breakpoint-xxl: 1400px;
$breakpoint-xl: 1200px;
$breakpoint-lg: 992px;
$breakpoint-md: 768px;
$breakpoint-sm: 576px;
$breakpoint-xs: 414px;

// Components
// Define common padding and border radius sizes and more.
$border-width: 1px;

$border-radius: 0.25rem; // 4px
$border-radius-6: 0.375rem; // 6px
$border-radius-12: 0.75rem; // 12px
$border-radius-16: 1rem; // 16px
$border-radius-24: 1.5rem; // 24px
$border-radius-32: 2rem; // 32px
$rounded-pill: 6.25rem; // 100px

/* box shadow */
$box-shadow-xlg: 0 20px 32px 0 rgba($gray, 0.24);
$box-shadow-lg: 0 16px 24px 0 rgba($gray, 0.16);
$box-shadow-md: 0 8px 16px 0 rgba($gray, 0.3);
$box-shadow: 0 4px 8px 0 rgba($gray, 0.3);
$box-shadow-0: none;
/* box shadow: */

$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;

$transition-base: all 0.3s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

//Gradiant Style
$fallback: $primary;
$from: $primary;
$to: darken($primary, 10);
$direction: 225deg;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: (
);
$spacers: map-merge((0: 0,
      1: ($spacer * 0.25,
      ),
      2: ($spacer * 0.5,
      ),
      3: $spacer,
      4: ($spacer * 1.5,
      ),
      5: ($spacer * 2,
      ),
      6: ($spacer * 2.5,
      ),
      7: ($spacer * 3,
      ),
    ),
    $spacers);

// Sidebar width-navbar-margin-spacing
$nav-sidebar-width: 335px;