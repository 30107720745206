.happy-unhappy-section {
  .course-detail {
    padding-top: 0 !important;

    h6 {
      margin-bottom: 10px !important;
    }
  }

  .course-info {
    display: flex;
    align-items: center;

    .happy-unhappy-box {
      background: $body-bg;
      border-radius: $border-radius-12;
      padding: size(4) size(14);
      margin-right: size(14);

      &.happy {
        background: $success;

        .bx-happy-alt {
          color: $white;
        }
      }

      &.unhappy {
        background: $danger;

        .bx-sad {
          color: $white;
        }
      }

      .bx-happy-alt {
        font-size: size(32);
        color: $success;
      }

      .bx-sad {
        font-size: size(32);
        color: $danger;
      }
    }
  }
}

.happy-unhappy-modal {
  .modal-body {
    padding: 0;
  }

  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: size(20) size(30);
    border-radius: $border-radius-24 $border-radius-24 0 0;

    h6 {
      font-size: size(15);
      line-height: size(22);
    }

    &.happy {
      background-color: $success;

      h6 {
        color: $white;
      }
    }

    &.unhappy {
      background-color: $danger;

      h6 {
        color: $white;
      }
    }
  }

  .content-box {
    padding: size(5) size(30) size(30) size(30);

    .media-box {
      img {
        width: 100%;
        height: 190px;
        object-fit: cover;
      }

      .embed-responsive,
      iframe {
        height: 190px;
      }
    }

    .inner-content {
      padding: size(20);

      p {
        font-size: size(15);
        line-height: size(22);
        letter-spacing: size(-0.12);
      }

      ul {
        li {
          display: flex;
          align-items: flex-start;

          .bx {
            font-size: size(12);
            color: $gray;
            margin-top: size(3);
            margin-right: size(6);
          }

          p {
            margin-bottom: size(5);
          }
        }
      }
    }
  }

  .select-options {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-arrow {
    a {
      font-size: size(24);
      color: $gray;

      &:hover {
        color: $secondary;
      }
    }

    &.prev-arrow {
      margin-right: size(7);
    }

    &.next-arrow {
      margin-left: size(7);
    }
  }

  .happy-unhappy-select {
    display: flex;
    align-items: center;

    .radio-box {
      margin: size(0) size(7);

      input[type="radio"] {
        display: none;

        &:checked {
          +label {
            &.happy {
              background: $success;

              .bx-happy-alt {
                color: $white;
              }

              h6 {
                color: $white;
              }
            }

            &.unhappy {
              background: $danger;

              .bx-sad {
                color: $white;
              }

              h6 {
                color: $white;
              }
            }
          }
        }
      }

      label {
        background: $body-bg;
        border-radius: $border-radius-16;
        padding: size(14);
        text-align: center;
        width: 130px;
        margin-bottom: 0;
        cursor: pointer;

        .bx-happy-alt {
          font-size: size(45);
          color: $success;
        }

        .bx-sad {
          font-size: size(45);
          color: $danger;
        }

        h6 {
          color: $gray;
          margin-top: size(10);
        }
      }
    }
  }
}

// Tour 360
.tour-360 {
  max-width: size(460);
  margin: 0 auto;
}

.view360 {
  width: 100%;

  div {
    &.componentContainer {
      width: 100%;
      height: size(700);
      min-height: 0;
      min-width: 100%;
    }

    .vrButton {
      bottom: 6px;
      right: 6px;
    }

    button.webvr-ui-button {
      border: none !important;
      background-color: transparent !important;
      color: $primary;
      font-size: size(22);

      &::before {
        content: "\ea4d";
        font-family: "boxicons", sans-serif;
      }

      .webvr-ui-logo {
        display: none;
      }
    }
  }
}

// Video Guide
.video-guide-banner {
  text-align: center;
  margin-bottom: size(22);
}

.video-item {
  border: 1px solid $border-color-light;
  padding: size(12) size(10);
  border-radius: $border-radius;
  margin-bottom: size(16);
  background-color: $white;

  .title-box {
    @include flex-between;

    h6 {
      font-size: size(15);
      line-height: size(22);
      color: $primary;
      margin-bottom: size(6);
    }

    .bx {
      font-size: 24px;
      color: $secondary;
    }
  }

  ul {
    padding-left: size(20);

    li {
      font-size: size(12);
      line-height: size(18);
      list-style: disc;
    }
  }

  &.step-done {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    box-shadow: $box-shadow-md;

    h6 {
      color: $white;
    }

    .title-box {
      .bx {
        color: $white;
      }
    }
  }
}

.step-main {
  max-width: 376px;
  margin: 0 auto;

  .progress {
    margin-bottom: size(30);
  }

  .step-content {
    max-width: size(330);
    margin: size(20) auto 0;

    p {
      font-size: size(15);
    }
  }
}

// Quiz
.quiz-main {
  text-align: center;
  padding: size(36) 0;

  @include media(down, $breakpoint-xl) {
    padding: size(22) 0;
  }
}

.quiz-image {
  margin-top: size(66);
  margin-bottom: size(66);

  @include media(down, $breakpoint-xl) {
    margin-top: size(46);
    margin-bottom: size(46);
  }
}

.quiz-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  .quiz-img {
    max-width: 35%;
    margin: 0 auto size(24);
  }

  .quiz-disc {
    h5 {
      font-size: 2rem;
      line-height: 2.25rem;
    }
  }
}

.quiz-radio {
  label {
    width: 100%;
    margin-right: 0;
    margin-bottom: size(10);
    counter-increment: listStyle;

    .bx {
      display: none;
      position: absolute;
      right: size(12);
      top: 50%;
      transform: translate(0, -50%);
      font-size: size(24);
    }

    .quizradio-main {
      font-size: 1.25rem !important;
      color: $dark;
      background-color: $white;
      padding: size(14) size(36);
      display: block;
      font-size: size(16);
      cursor: pointer;
      border-radius: $border-radius;
      border: solid 1px $border-color-light;
      position: relative;

      &::before {
        content: counter(listStyle, upper-alpha);
        font-size: size(15);
        font-weight: $font-bold;
        color: $primary;
        position: absolute;
        left: size(16);
        top: size(14);
        z-index: 1;
        pointer-events: none;
      }
    }

    input[type="radio"] {
      display: none;

      &:checked {
        +.quizradio-main {
          background-color: $primary;
          border-color: $primary;
          color: $white;
          box-shadow: $box-shadow-md;

          &::before {
            color: $white;
          }

          .bx {
            display: block;
          }
        }
      }
    }
  }
}

.quiz-next-prev {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: size(30);
  margin-bottom: size(12);

  .bx {
    font-size: size(60);
    color: $primary;

    &.bx-prev {
      transform: rotate(180deg);
      width: 100%;
    }

    &:hover {
      color: $primary;
    }
  }

  .btn-review {
    position: absolute;
    left: 48%;
    right: 0;
    width: max-content;
    transform: translate(-50%, -50%);
    top: 50%;
  }
}

.quiz-review-section {
  overflow-y: scroll;
  max-height: 440px;

  .course-item-box {
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    .info-over {
      .bx-edit-alt {
        color: $primary;

        &:hover {
          color: $secondary;
        }
      }
    }

    .quiz-answer {
      display: block;
      width: 100%;
      border-top: 1px solid $border-color-light;
      padding-top: size(10);
      margin-top: size(10);

      p {
        margin-bottom: 0;
        font-size: size(15);
        display: flex;
        align-items: center;

        .bx {
          color: $secondary;
          font-size: size(24);
          margin-right: size(8);
        }
      }
    }
  }
}

.quiz-result-section {
  .quiz-result-box {
    text-align: center;
    background: $white;
    border-radius: $border-radius-12;
    box-shadow: $box-shadow-lg;
    padding: size(50);

    @include media(down, $breakpoint-xl) {
      padding: size(30);
    }

    .quiz-img-box {
      margin-bottom: size(30);
    }

    h2 {
      margin-bottom: size(8);
      color: $secondary;
    }

    p {
      font-size: size(15);
      line-height: size(18);
      margin-bottom: 0;
    }

    .btn {
      margin-top: size(22);
    }

    .previous-text {
      margin-top: size(8);
      color: $gray;

      a {
        font-weight: $font-bold;
        color: $dark;

        &:hover {
          color: $primary;
        }
      }
    }

    .flex-action {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: size(22);

      .btn {
        margin: 0 6px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .quiz-result-pdf {
    border: 1px solid $border-color-light;
    background: $white;
    border-radius: $border-radius;
    margin-top: size(30);
    padding: size(18);
    display: flex;
    align-items: center;

    .pdf-img {
      flex: 0 0 auto;

      .bx {
        font-size: size(30);
        color: $secondary;
      }
    }

    .result-info {
      padding-left: size(10);

      h6 {
        font-size: size(15);
        line-height: size(18);
        margin-bottom: 0;
      }

      span {
        font-size: size(12);
        line-height: size(16);
        color: $gray;
      }
    }
  }
}

.previous-result {
  .prev-result-box {
    border: 1px solid $border-color-light;
    background: $white;
    border-radius: $border-radius;
    margin-bottom: size(14);
    padding: size(15);
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-box {
      flex: 0 0 auto;

      .bx {
        font-size: size(36);
        color: $secondary;
      }
    }

    .result-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: size(10);
      width: 100%;

      h6 {
        font-size: size(15);
        line-height: size(18);
        margin-bottom: 0;
      }

      span {
        font-size: size(12);
        line-height: size(16);
        color: $gray;
      }

      .bx {
        font-size: size(24);
        color: $gray;
      }
    }
  }
}

// Upload Job
.job-item-box {
  background: $white;
  border: 1px solid $border-color-light;
  padding: size(10);
  margin-bottom: size(16);
  border-radius: $border-radius;
  position: relative;
  cursor: pointer;
  @include Transition(all 0.3s ease-in-out);

  &:hover {
    box-shadow: $box-shadow-lg;
  }

  .flex-item-box {
    display: flex;
  }

  .job-status {
    padding: size(4);
    border-radius: $border-radius;
    background-color: $gray-200;
    width: 74px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    span {
      font-size: size(12);
      font-weight: $font-bold;
      line-height: size(18);
      color: $dark;
      text-align: center;
      text-transform: uppercase;
    }

    &.approved {
      background-color: rgba($secondary, 20%);

      span {
        color: $secondary;
      }
    }

    &.pending {
      background-color: rgba($warning, 20%);

      span {
        color: $warning;
      }
    }

    &.rejected {
      background-color: rgba($primary, 20%);

      span {
        color: $primary;
      }
    }
  }

  .job-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: size(10);
    width: calc(100% - 74px);
  }

  .arrow-ic {
    font-size: size(24);
    color: $dark;
  }

  .job-item {
    margin-bottom: size(4);

    h6 {
      font-size: size(13);
      line-height: size(18);
      margin-bottom: 0;
      letter-spacing: size(-0.03);
    }

    span {
      font-size: size(12);
      line-height: size(16);
      display: block;
      color: $gray;
    }
  }
}

.job-details-section {
  .upload-title {
    font-size: size(15);
    margin-bottom: size(12);
  }

  .file-uploads {
    display: flex;

    .filelabel {
      width: 106px;
      height: size(97);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .upload-item {
      margin-right: size(15);
      text-align: center;

      &:last-child {
        margin-right: 0;
      }
    }

    .upload-file-name {
      margin-bottom: 0;
      margin-top: size(5);
      display: flex;
      align-items: center;
      justify-content: center;

      .bx {
        font-size: size(24);
        color: $primary;
        margin-right: size(4);
      }
    }
  }
}

.filter-modal-inner {
  .filter-btn {
    font-size: size(13);
    line-height: size(18);
    color: $primary;
    font-weight: $font-bold;
  }

  .jobs-options {
    h5 {
      margin-bottom: size(16);
      margin-top: size(16);
    }
  }
}

// Image Gallery
.image-gallery-preview {
  .preview-content {
    max-width: 351px;
    margin: 0 auto;
    text-align: center;
  }

  .gallery-info {
    margin-bottom: size(40);

    span {
      color: $primary;
      font-size: size(15);
      font-weight: $font-bold;
    }
  }

  .slick-dots {
    li {
      height: 8px;
      width: 8px;
      margin: 0 4px;

      button {
        height: 8px;
        width: 8px;
        padding: 0;

        &::before {
          height: 8px;
          width: 8px;
          font-size: 8px;
          line-height: 8px;
          color: $gray;
          opacity: 1;
        }
      }

      &.slick-active {
        button:before {
          color: $secondary;
          opacity: 1;
        }
      }
    }
  }

  .vertical-slider {
    margin-bottom: 60px;

    .slick-arrow {
      top: inherit;
      bottom: -50px;
      transform: none;
      margin: 0 auto;
      left: 0;
      right: 0;

      &::before {
        display: none;
      }
    }

    .slick-prev {
      background: url(../../assets/images/up-arrow.svg);
      width: 32px;
      height: 32px;
      left: -40px;
    }

    .slick-next {
      background: url(../../assets/images/down-arrow.svg);
      width: 32px;
      height: 32px;
      right: -40px;
    }
  }
}

// Upload Video
.upload-video-section {
  max-width: 700px;
  margin: 0 auto;
  height: calc(100vh - 136px);
  display: flex;
  align-items: center;
  justify-content: center;
}

// Title Slide
.title-slide-section {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  min-height: 575px;
  max-width: 500px;
  margin: 0 auto;

  .content-box {
    width: 100%;
  }

  h2 {
    color: $primary;
    margin-bottom: size(12);
  }

  p {
    max-width: 220px;
    margin: 0 auto;
  }

  .btn {
    margin: size(24) size(0);
  }
}

// Confirmation
.check-for-kit-section {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 575px;
  max-width: 500px;
  margin: 0 auto;

  .content-box {
    width: 100%;
  }

  h2 {
    font-weight: $font-bold;

    span {
      color: $primary;
    }
  }

  .link-box {
    margin-top: size(30);

    a {
      margin: 0 6px;
      border-radius: $rounded-pill;
    }
  }
}

// Photo Hotspot
.photo-hotspot-modal {
  .img-box {
    text-align: center;
    margin-bottom: size(30);

    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }
}

// Image Hotspot
.image-hotspot-section {
  max-width: 375px;

  .hotspot-info {
    background-color: $white;
    border: 1px solid $border-color-light;
    border-radius: $border-radius;
    padding: size(20);
    display: flex;
    align-items: center;

    h5 {
      margin-bottom: 0;
      width: 50%;
      text-align: center;
    }

    span {
      color: $gray;
      font-size: size(18);
      line-height: size(24);
      font-weight: $font-bold;
    }
  }
}

.image-hotspot-modal {
  .modal-body {
    padding: size(40);
    text-align: center;
  }
}