.contact-map {
    iframe {
        width: 100%;
    }
}

.contact-info {
    padding: size(10) size(14);

    .cf-row {
        @include flex-between;
        border-bottom: solid 1px $border-color-light;
        padding-top: size(28);
        padding-bottom: size(28);

        @include media(down, $breakpoint-xl) {
            padding-top: size(20);
            padding-bottom: size(20);
        }
    }

    .cf-icon {
        width: size(60);
        text-align: center;
        font-size: size(24);
        color: $primary;
    }

    .cf-content {
        width: calc(100% - 60px);
        font-size: size(16);
        font-weight: $font-bold;
        color: $gray;

        a {
            color: $primary;

            &:hover {
                color: $secondary;
            }
        }

        .color-gray {
            color: $gray;

            &:hover {
                color: $secondary;
            }
        }
    }

    label {
        color: $dark;
        font-size: size(18);
        font-weight: $font-bold;
        margin-bottom: size(6);
    }
}

.contact-footer {
    text-align: center;
    padding: size(20) size(20) 0 size(20);

    li {
        list-style-type: none;
        margin-bottom: size(8);
    }

    a {
        color: $gray;
        font-size: size(15);

        &:hover {
            color: $primary;
        }
    }
}