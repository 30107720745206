.login-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  overflow: overlay;
  overflow-x: hidden;
  background-image: url(../../assets/images/login-bg.jpg);

  .login-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    margin: 1.75rem auto;

    @include media(down, $breakpoint-sm) {
      padding: 0 size(15);
    }

    .login-container {
      background: $white;
      max-width: 540px;
      width: 100%;
      margin: 0 auto;
      padding: size(50);
      border-radius: $border-radius;

      @include media(down, $breakpoint-sm) {
        padding: size(30) size(15);
      }
    }

    .field-icon {
      cursor: pointer;
      position: absolute;
      right: size(16);
      top: size(40);
      font-size: size(24);
      color: $pastel-grey;

      @include media(down, $breakpoint-md) {
        font-size: size(22);
        top: size(36);
      }

      &:hover {
        color: $primary;
      }
    }

    .logo-box {
      text-align: center;
    }

    .login-content {
      text-align: center;
      margin: size(45) size(0) size(30) size(0);
    }

    .forgot-password {
      text-align: center;
      margin-top: size(22);
    }

    .login-bottom {
      text-align: center;
      border-top: 1px solid $border-color;
      margin-top: size(50);
      padding-top: size(36);

      p {
        font-size: size(15);
        margin-bottom: size(22);
      }
    }

    .custom-checkbox {
      label {
        font-size: size(15);
      }
    }
  }
}

.mfa-modal {
  &.modal-dialog {
    &.modal-lg {
      max-width: 540px;
    }
  }

  .login-wrapper {
    background-image: none;
    height: auto;

    .login-body {
      min-height: auto;
      margin: 0;
    }
  }

}