.admin-content-wrapper {
    padding: 106px 30px 30px 365px;

    @include media(down, $breakpoint-xxl) {
        padding-left: 330px;
    }

    @include media(down, $breakpoint-xl) {
        padding-left: 270px;
    }
}

.card {
    border-color: $border-color;
    border-radius: $border-radius-24;
    padding: size(30);

    @include media(down, $breakpoint-xl) {
        padding: size(20);
        border-radius: $border-radius-12;
    }

    &.remove-pad {
        padding: 0;
    }

    .card-body {
        padding: 0;
    }
}

.color-primary {
    color: $secondary;
}

.action-btn {
    text-align: center;
    margin-top: size(30);
}

.page-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: size(30);

    h4 {
        margin-bottom: 0;
    }

    .button-box {
        background: $white;
        font-size: size(13);
        line-height: size(18);
        letter-spacing: size(-0.03);
        color: $dark;
        font-weight: $font-regular;
        padding: size(6) size(8);
        border: 1px solid $border-color;
        border-radius: $border-radius;
        height: 32px;
        display: flex;
        align-items: center;

        .bx {
            font-size: size(16);
            color: $gray;
            margin-right: size(8);
            @include Transition(all 0.3s ease-in-out);
        }

        &:hover {
            background: $primary;
            border-color: $primary;
            color: $white;

            .bx {
                color: $white;
            }
        }
    }
}

.filelabel {
    border: 2px dashed $border-color-light;
    border-radius: 16px;
    display: block;
    padding: 5px;
    transition: border 300ms ease;
    cursor: pointer;
    text-align: center;
    margin: 0;

    .bx {
        background-color: #D0EEF0;
        width: size(40);
        height: size(40);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary;
        font-size: size(18);
    }

    &.with-text {
        @include flex-between;
        text-align: left;
        padding: size(16);
    }

    .upload-text {
        width: calc(100% - 56px);
        font-size: size(16);
        font-weight: $font-bold;
        margin: 0;
        color: $dark;
    }
}

.fileuploadinout {
    display: none;
}